<!-- 添加房屋信息 -->
<template>
  <div>
    <!-- 位置信息 -->
    <div style="margin-bottom: 15px">
      <ax-form ref="formBox" :formBuilder="formBuilder">
        <div
          style="
            border-left: 4px solid #556bff;
            font-size: 16px;
            font-weight: bold;
            color: black;
            line-height: 100%;
            margin-bottom: 15px;
          "
          slot="Group1"
        >
          &nbsp;位置信息
        </div>
        <div
          style="
            border-left: 4px solid #556bff;
            font-size: 16px;
            font-weight: bold;
            color: black;
            line-height: 100%;
            margin-bottom: 15px;
          "
          slot="Group2"
        >
          &nbsp;房源信息
        </div>
      </ax-form>
    </div>

    <!-- 房主信息 -->
    <div style="margin-bottom: 15px">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
        "
      >
        <div
          style="
            border-left: 4px solid #556bff;
            font-size: 16px;
            font-weight: bold;
            color: black;
            line-height: 100%;
            margin-bottom: 15px;
          "
        >
          &nbsp;房主信息
        </div>
        <div>
          <ax-button @click="showHouse">新增</ax-button>
          <el-dialog
            title="选择居民"
            width="1000px"
            :visible.sync="houseVisible"
            :z-index="12"
            :destroy-on-close="true"
            :close-on-click-modal="false"
          >
            <houseOwner
              ref="houseOwner"
              @close="houseVisible = false"
              @selection-change="handleSelectionChangehouse"
            />
          </el-dialog>
          <ax-button type="#f95a5a" style="margin-left: 10px" @click="delete1"
            >删除</ax-button
          >
        </div>
      </div>

      <a-table
        :data-source="data2"
        :columns="columns"
        bordered
        :pagination="false"
        :rowSelection="{
          onChange: onSelectChange,
        }"
        :key="tableKey"
      />
    </div>
    <!-- 底部 -->
    <div style="display: flex; justify-content: flex-end">
      <a-button type="primary" @click="onSubmit" :loading="saveType">
        保存
      </a-button>
      <a-button style="margin-left: 20px" @click="closeDialog"> 取消 </a-button>
    </div>
  </div>
</template>
  <script>
import api from "../api";
import practical from "./practical.vue";
import houseOwner from "./houseOwner.vue";
const options = [
  { label: "已住", value: "0" },
  { label: "未住", value: "1" },
];
const houseOrientation = [
  { label: "南", value: "南" },
  { label: "东南", value: "东南" },
  { label: "东", value: "东" },
  { label: "西南", value: "西南" },
  { label: "北", value: "北" },
  { label: "西北", value: "西北" },
  { label: "西", value: "西" },
  { label: "东北", value: "东北" },
];
const houseUsage = [
  { label: "自住", value: "自住" },
  { label: "商用", value: "商用" },
  { label: "其他", value: "其他" },
];
const formList = [
  {
    label: "",
    type: "",
    model: "Group1",
    options: {},
    formItem: { colon: false },
    col: { span: 24 },
  },
  {
    label: "所属网格",
    type: "",
    model: "gridName",
    options: { placeholder: "输入内容", showTime: true, disabled: true },
    col: { span: 12 },
    rules: [{ required: true, message: "所属网格不能为空" }],
  },
  {
    label: "所属小区",
    type: "",
    model: "cellName",
    options: { placeholder: "输入内容", showTime: true, disabled: true },
    col: { span: 12 },
    rules: [{ required: true, message: "所属小区不能为空" }],
  },

  {
    label: "所属单元",
    type: "",
    model: "floorName",
    options: { placeholder: "输入内容", showTime: true },
    col: { span: 12 },
    rules: [{ required: true, message: "所属单元不能为空" }],
  },
  {
    label: "楼层",
    type: "",
    model: "unitName",
    options: { placeholder: "输入内容", showTime: true },
    col: { span: 12 },
    rules: [{ required: true, message: "楼层不能为空" }],
  },
  {
    label: "",
    type: "",
    model: "Group2",
    options: {},
    formItem: { colon: false },
    col: { span: 24 },
  },
  {
    label: "房屋朝向",
    type: "select",
    model: "houseOrientation",
    options: {
      placeholder: "请选择",
      options: houseOrientation,
    },
    col: { span: 12 },
  },
  {
    label: "房间数",
    type: "",
    model: "roomCount",
    options: { placeholder: "请输入...", maxLength: 10 },
    col: { span: 12 },
    rules: [
      {
        required: false,
        pattern: /^[1-9]\d{0,9}$/,
        message: "房间数格式不正确",
      },
    ],
  },
  {
    label: "房源用途",
    type: "select",
    model: "houseUsage",
    options: { options: houseUsage, placeholder: "请选择" },
    col: { span: 12 },
  },

  {
    label: "门牌号",
    type: "",
    model: "doorPlate",
    options: { placeholder: "输入内容", showTime: true },
    col: { span: 12 },
    rules: [{ required: true, message: "户号不能为空" }],
  },
  {
    label: "房屋面积(m²)",
    type: "",
    model: "houseArea",
    options: { placeholder: "请输入...", maxLength: 10 },
    col: { span: 12 },
    rules: [
      {
        required: true,
        pattern: /^[A-Za-z0-9\u4e00-\u9fa5]{1,20}$/,
        message: "房屋面积不能为空",
      },
    ],
  },
  {
    label: "居住现状",
    type: "radio",
    model: "houseType",
    options: { options, showTime: true },
    col: { span: 12 },
    rules: [{ required: true, message: "请选择" }],
  },
];

const data2 = [];
export default {
  components: {
    practical,
    houseOwner,
  },
  props: ["buildingId"],
  data() {
    return {
      api,
      sqlParams: {},
      data2,
      row: "",
      tableKey: 0, // 用于触发组件更新的唯一键
      saveType: false,
      aww: "",
      houseVisible: false,
      selectionRows2: [],
      selectionRows: [],
      gridId: "",
      information: [],
      information2: [],
      gridName: "",
      cellName: "",
      actualPopulation: "",
      rowSelection: {},
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 130 },
        { col: { span: 8 } }
      ),
      columns2: [
        {
          title: "姓名",
          dataIndex: "name",
          ellipsis: true,
          width: 120,
          align: "center",
        },
        {
          title: "身份证",
          dataIndex: "idcard",
          ellipsis: true,
          width: 180,
          align: "center",
        },
        {
          title: "联系方式",
          dataIndex: "phone",
          ellipsis: true,
          width: 120,
          align: "center",
        },
        {
          title: "居民类型",
          dataIndex: "residentType",
          ellipsis: false,
          width: 120,
          align: "center",
          customRender: (text) => {
            return text === "0" ? "常住人口" : text === "1" ? "流入人口" : "";
          },
        },
      ],
      columns: [
        {
          title: "姓名",
          dataIndex: "ownerName",
          ellipsis: true,
          width: 120,
          align: "center",
        },
        {
          title: "身份证",
          dataIndex: "idcard",
          ellipsis: true,
          width: 180,
          align: "center",
        },
        {
          title: "联系方式",
          dataIndex: "phone",
          ellipsis: true,
          width: 120,
          align: "center",
        },
        {
          title: "居民类型",
          dataIndex: "residentType",
          ellipsis: false,
          width: 120,
          align: "center",
          customRender: (text) => {
            return text === "0" ? "常住人口" : text === "1" ? "流入人口" : "";
          },
        },
      ],
    };
  },
  watch: {
    houseVisible(newVal, oldVal) {
      if (!newVal && oldVal) {
        this.addDataOnHouseVisibleChange();
        if (this.selectionRows && this.selectionRows.length > 0) {
          this.selectionRows = []; // 清空选中的缓存
        }
      }
    },
    rowSelection(e) {},
  },
  computed: {},
  created() {},
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        const res = await api.getByBuilId(this.buildingId);
        this.gridName = res.data.gridName;
        this.cellName = res.data.cellName;

        if (res && res.data) {
          this.$refs.formBox.setFieldsValue(res.data); // Assign values
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    //房主
    handleSelectionChangehouse(selectionRows) {
      if (selectionRows && selectionRows.length > 0) {
        this.selectionRows = selectionRows;
        // console.log(this.selectionRows);
        // 创建一个空数组，用来存储房主信息
        let houseOwnerInfo = [];
        // 遍历 selectionRows 数组，将房主信息拿出来存储到 houseOwnerInfo 数组中
        this.selectionRows.forEach((aww) => {
          let ownerInfo = {
            ownerId: aww.id,
            ownerName: aww.residentName,
            idcard: aww.identityCard,
            phone: aww.phone,
            residentType: aww.type,
          };
          houseOwnerInfo.push(ownerInfo);
        });

        this.information = houseOwnerInfo; // 将房主信息数组赋值给 this.data2
        this.tableKey++; // 修改 tableKey 的值以触发组件重新渲染
      }
    },
    addDataOnHouseVisibleChange() {
      if (this.selectionRows && this.selectionRows.length > 0) {
        // 在这里执行添加数据的操作，
        this.data2 = this.information;
        this.tableKey++; // 修改 tableKey 的值以触发组件重新渲染
      }
    },
    delete1() {
      const selectedRows = this.selectionRows2;
      if (selectedRows.length > 0) {
        this.data2 = this.data2.filter((item) => !selectedRows.includes(item));
        // 清除与删除数据相关的勾选状态
        this.selectionRows2 = [];
        this.tableKey++; // 修改 tableKey 的值以触发组件重新渲染
      } else {
        console.log("没有选中数据");
      }
    },
    //房主
    showHouse() {
      this.houseVisible = true;
    },
    async onSubmit() {
      this.$refs.formBox.form.validateFields(async (err, value) => {
        if (err) return;
        value.buildingId = this.buildingId;
        value.ownerId =
          this.data2.length > 0 ? this.data2[0].ownerId || "" : "";
        value.ownerName =
          this.data2.length > 0 ? this.data2[0].ownerName || "" : "";
        value.idcard = this.data2.length > 0 ? this.data2[0].idcard || "" : "";
        value.phone = this.data2.length > 0 ? this.data2[0].phone || "" : "";
        value.residentType =
          this.data2.length > 0 ? this.data2[0].residentType || "" : "";

        this.saveType = true;
        try {
          const editRes = await api.houseAdd(value);
          if (editRes.status === 200) {
            this.$message.success("新增成功");
            this.saveType = false;
            this.closeDialog(); // 在所有操作完成后关闭弹窗
            this.refreshTable();
          } else {
            this.$message.error("新增失败");
            this.saveType = true;
          }
        } catch (error) {
          this.$message.error("新增失败");
          this.saveType = false; // 捕获错误时设置为 false
        }
      });
    },
    //关闭弹窗
    closeDialog() {
      this.$emit("close");
    },
    //刷新代码
    refreshTable() {
      this.$emit("refreshTable");
      this.$emit("close");
    },
    //多选上传对象
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectionRows2 = selectionRows;
    },
  },
};
</script>
      
  <style lang="less" scoped>
/deep/.ant-modal {
  top: 0;
}
/deep/.a-input-group {
  text-align: center; /* 将 .a-input-group 内的文本水平居中排列 */
}
// /deep/ .ant-select-selection--single {
//   height: 100% !important;
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
// }
.empty {
  margin-top: 40px;
}
.collapse-container {
  display: flex;
  flex-wrap: wrap; /* 让元素自动换行 */
}
.collapse {
  width: 0.4rem;
  height: 0.2rem;
  border-radius: 6px;
  background-color: #f0f0f0;
  margin-right: 10px; /* 可以根据需要调整元素之间的间距 */
  margin-bottom: 10px; /* 可以根据需要调整元素之间的间距 */
  display: flex; /* 让内容垂直居中 */
  justify-content: space-around;
  align-items: center; /* 让内容垂直居中 */
}
.close-icon {
  margin-left: 5px; /* 调整图标与文本之间的间距 */
  cursor: pointer; /* 添加指针样式，表示可点击 */
}
</style>
      