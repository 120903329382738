<!-- 选择房主信息 -->
<template>
  <div>
    <ax-table
      ref="roleTable3"
      :show-search="true"
      :searchForm="searchForm"
      :searchActions="searchActions"
      :columns="columns"
      :dataSourceApi="api.listhouse"
      :dataSourceParams="dataSourceParams"
      :sqlParams="sqlParams"
      :clickable="false"
      :rowSelection="{
        onChange: onSelectChange,
        selectedRowKeys: selectedRowKeys,
        getCheckboxProps: getCheckboxProps,
      }"
    >
    </ax-table>
    <div style="display: flex; justify-content: flex-end; margin-top: 20px">
      <a-button type="primary" @click="onSubmit"> 保存 </a-button>
      <a-button style="margin-left: 20px" @click="close"> 取消 </a-button>
    </div>
  </div>
</template>
        
    <script>
import api from "../api";
const searchForm = [
  {
    label: "请输入关键字",
    type: "",
    model: "searchText",
    options: { placeholder: "请输入", showTime: true },
    col: { span: 8 },
  },
];
export default {
  data() {
    return {
      api,
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 90,
      }),
      searchActions: [
        { name: "search", text: "查询" },
        { name: "reset", text: "重置" },
      ],
      columns: this.$ax.tools.initColumn([
        {
          title: "姓名",
          dataIndex: "residentName",
          maxWidth: 120,
        },
        {
          title: "身份证号",
          dataIndex: "identityCard",
          maxWidth: 120,
        },
        {
          title: "现住区域",
          dataIndex: "currentArea",
          ellipsis: true,
          maxWidth: 120,
        },
      ]),
      dataSourceParams: {},
      sqlParams: {},
      rowSelection: {},
      selectionRows: [],
      selectedRowKeys: [],
      selectItems: [],
    };
  },
  watch: {
    rowSelection(e) {
      console.log(e);
    },
    selectItems(newVal, oldVal) {
      if (newVal) {
        this.projectdata();
      }
    },
  },
  computed: {},
  created() {},
  methods: {
    projectdata() {
      if (!this.selectItems) {
        console.error("selectItems is undefined");
        return;
      }
      const residentIds = this.selectItems.map((item) => item.residentId);
      this.selectedRowKeys = residentIds;
      this.$nextTick(() => {
        this.$refs.roleTable3.getDataSource();
      });
    },
    //多选上传对象
    onSelectChange(selectedRowKeys, selectionRows) {
      //调用父组件的selection-change的方法，将selectionRows传给父组件
      this.selectionRows = selectionRows;
      this.selectedRowKeys = selectedRowKeys;
      console.log(this.selectedRowKeys);
    },
    getCheckboxProps(record) {
      return {
        props: {
          disabled: this.selectedRowKeys.includes(record.id),
        },
      };
    },
    //提交
    onSubmit() {
      this.$emit("selection-change", this.selectionRows);
      this.$emit("close");
    },
    //重置
    reset() {},
    //关闭弹窗
    close() {
      this.$emit("close");
    },
  },
  mounted() {
    this.projectdata();
  },
};
</script>
        
    <style lang="scss" scoped>
</style>
        